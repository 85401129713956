@font-face {
  font-family: 'Montserrat Lite';
  src: url('../fonts/Montserrat Light 300.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('../fonts/Montserrat Bold 700.ttf') format('truetype');
}

:root {
  --font-main: 'Montserrat Lite', 'Helvetica', sans-serif;
  --font-bold: 'Montserrat Bold', 'Helvetica', sans-serif;
  --color-primary: #cfa867;
  --color-secondary: #000;
  --filter-white: invert(100%) sepia(0%) saturate(7497%) hue-rotate(155deg) brightness(108%) contrast(96%);
}

.stronger {
  font-family: var(--font-bold);
}

.spaced {
  margin-bottom: 60px;
}

.nounderline {
  text-decoration: none;
}

.filter-primary {
  filter: var(--filter-primary);
}

a {
  color: var(--color-primary);
}

a:hover {
  color: var(--color-primary);
}

h2 {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 60px;
}

h2 img {
  width: 50px;
  height: 50px;
  margin: 0px auto 10px;
  display: block;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: var(--font-main);
}

div {
  margin: 0;
  padding: 0;
}

.close_btn i {
  background-image: url("../images/icons/xmark.svg");
  filter: var(--filter-white);
  background-repeat: no-repeat;
  background-position: center;
}

#infoDIV {
  top: 20px;
  right: 0;
  position: fixed;
  z-index: 9999;
  min-height: 0;
  cursor: pointer;
  overflow: hidden;
  color: #fff;
  padding: 0;
  margin: 0;
}

#infoDIV>div {
  left: 300px;
  width: 300px;
  min-height: 40px;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

#infoDIV span {
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

#infoDIV i {
  width: 100%;
  height: 100%;
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  filter: var(--filter-white);
}

#infoDIV p {
  font-family: 'arial-regular', sans-serif;
  font-size: 0.9rem;
  color: #fff;
  width: calc(100% - 40px);
  min-height: 20px;
  position: relative;
  margin: 0 0 0 40px;
  padding: 10px;
  display: flex;
  align-items: center;
}

#infoDIV div.alert-success span {
  background-color: rgba(121, 188, 107, 1);
}

#infoDIV div.alert-success p {
  background-color: rgba(121, 188, 107, 0.8);
}

#infoDIV div.alert-warning span {
  background-color: rgba(253, 201, 93, 1);
}

#infoDIV div.alert-warning p {
  background-color: rgba(253, 201, 93, 0.8);
}

#infoDIV div.alert-danger span {
  background-color: rgba(236, 99, 95, 1);
}

#infoDIV div.alert-danger p {
  background-color: rgba(236, 99, 95, 0.8);
}

#infoDIV .alert-success i {
  background-image: url("../images/icons/status_check.svg");
}

#infoDIV .alert-warning i {
  background-image: url("../images/icons/status_exclamation.svg");
}

#infoDIV .alert-danger i {
  background-image: url("../images/icons/status_times.svg");
}

#loadingDIV {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9998;
  position: fixed;
}

#loadingDIV.active>i {
  width: 10vw;
  height: 10vw;
  max-width: 30px;
  max-height: 30px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url("../images/icons/gear.svg");
  display: block;
  animation: rotating-function 2s linear infinite;
  filter: var(--filter-white);
}

#privacyDIV {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9997;
  color: #fff;
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#privacyBody {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  position: relative;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0;
  box-sizing: border-box;
  padding: 20px 20px 100px 20px;
}

#privacyBody .close_btn {
  top: 0px;
  right: 1px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 10px;
  border: 1px solid var(--color-primary);
  border-radius: 5px 15px 5px 5px;
  color: var(--color-primary);
  cursor: pointer;
}

#privacyDIV a i {
  width: 100%;
  height: 100%;
  display: block;
}

#privacyDIV h2 {
  color: var(--bg-gold);
  margin-bottom: 30px;
}

#whatsappBTN {
  bottom: 5px;
  right: 5px;
  position: fixed;
  width: 50px;
  height: 50px;
  background-image: url("../images/whatsappBTN.png");
  background-size: contain;
  z-index: 9996;
  cursor: pointer;
}

#myModal {
  top: 0;
  left: 0;
  z-index: 9997;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  background-color: #fff;
  width: 90%;
  height: 90%;
  border-radius: 15px;
}

.modal-content,
.modal-body {
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
}

.modal-body {
  position: relative;
}

.modal-body>a {
  top: 0px;
  right: 0px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 10px;
  border: 1px solid var(--color-primary);
  border-radius: 5px 15px 5px 5px;
  color: var(--color-primary);
  cursor: pointer;
  z-index: 10;
}

.modal-body>a img {
  width: 70%;
  filter: var(--filter-primary);
}

.modal-body .sketch_box {
  width: 100%;
  height: 100%;
}

.menu {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 60px;
  z-index: 10;
  display: flex;
  align-items: center;
}

.menu>a {
  position: relative;
  display: inline-flex;
  height: auto;
  flex-grow: 1;
  align-items: center;
  justify-content: left;
  padding: 0 10px;
}

#logo {
  position: relative;
  left: 0px;
  width: 80%;
  max-width: 440px;
  height: auto;
}

.menu div {
  display: none;
}

.menu>span {
  position: relative;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  margin: 0 10px;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  border: 1px solid white;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.menu>span img {
  width: 50%;
  filter: var(--filter-white);
}

#menuDIV {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: var(--color-secondary);
  color: #fff;
  z-index: 9997;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menuDIV>a {
  position: absolute;
  top: 10px;
  right: 10px;
  border: 1px solid white;
  padding: 10px 10px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 15px;
  height: 15px;
}

#menuDIV img {
  width: 100%;
  filter: var(--filter-white);
}

#appointmentDIV img {
  width: 40px;
  display: block;
  margin: auto;
}

#appointmentDIV button {
  padding: 14px 25px;
  margin: 35px 0;
  background-color: var(--color-primary);
  -webkit-appearance: button-bevel;
  color: #fff;
  font-size: 1.2rem;
}

#divisionDIV::before,
#divisionDIV::after {
  content: "----------------------------";
  color: #fff;
  letter-spacing: -0.5rem;
  font-size: 1.2rem;
  position: relative
}

#divisionDIV::before {
  left: -10px;
}

#divisionDIV::after {
  left: 54px;
}

#whatsappBLK img {
  width: 40px;
  display: block;
  margin: 35px auto;
}

#whatsappBLK button {
  padding: 14px 25px;
  background-color: #fff;
  -webkit-appearance: button-bevel;
  color: var(--color-primary);
  font-size: 1.2rem;
}

#whatsappBLK p {
  font-size: 0.8rem;
  text-align: center;
  margin-top: 20px;
}

#mainDIV {
  top: 0;
  left: 0;
  position: relative;
  z-index: 1;
  width: 100vw;
  height: 100vh;
}

#mainDIV .slide {
  overflow: hidden;
  top: 0;
  left: 0;
  position: relative;
  height: 100%;
  background-size: cover;
  color: #fff;
}

#mainDIV .slide h1 {
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 10px;
  left: 10px;
  text-shadow: 2px 2px 10px #000;
  width: 90%;
}

footer {
  width: 100%;
  min-height: 120px;
  background-color: black;
  padding-top: 50px;
  position: relative;
}

footer a {
  color: var(--color-primary);
  text-decoration: none;
}

footer #socialDIV {
  top: 0;
  margin: auto;
  position: relative;
  text-align: center;
}

footer #socialDIV ul {
  list-style: none;
  padding: 0;
}

footer #socialDIV li {
  width: 30px;
  height: 30px;
  display: inline-block;
}

footer #socialDIV li+li {
  margin-left: 40px;
}

footer #socialDIV li img {
  width: auto;
  height: 30px;
  filter: var(--filter-white);
}

footer #termsDIV {
  padding: 40px 0 80px;
  position: relative;
  text-align: center;
  overflow: hidden;
  font-family: var(--font-bold);
}

footer #termsDIV img {
  margin: auto;
  display: block;
  width: 60%;
  max-width: 430px
}

/* appointments styles */

#menuAppointment {
  background-color: #000;
}

section {
  color: #000;
  text-align: center;
  padding-top: 100px;
  font-family: var(--font-bold);
}

.img_calendar {
  width: 40px;
  margin-bottom: 20px;
}

iframe {
  width: 100%;
  height: 1000px;
  border: 0px;
  background-color: transparent;
}

@keyframes rotating-function {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 425px) {
  .menu {
    background-color: rgba(0, 0, 0, 0.8);
    height: 80px;
    position: fixed;
  }

  .menu>a {
    display: inline-flex;
    flex-grow: 1;
  }

  .menu div {
    display: inline-flex;
    align-items: center;
    background-color: var(--color-primary);
    color: #fff;
    padding: 5px 20px;
    margin: 0 30px;
    font-family: var(--font-bold);
    flex-shrink: 0;
    width: 200px;
  }

  .menu div img {
    width: 40px;
    height: 40px;
    filter: var(--filter-white);
    margin-right: 20px;
  }

  .menu>span {
    display: none;
  }

  footer #socialDIV {
    top: 50px;
    right: 8%;
    position: absolute;
    text-align: center;
    z-index: 3;
  }

  footer #termsDIV {
    text-align: left;
    margin: 10px 0 0 50px;
    padding: 40px 0 0;
  }

  footer #termsDIV img {
    display: none;
  }
}