#descriptionDIV {
  top: 0;
  left: 0;
  position: relative;
  width: 100%;
  margin: 0;
  background-color: var(--color-primary);
  color: #000;
  padding: 40px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#descriptionDIV ul li {
  font-size: 1.4rem;
  margin: 0 0 10px;
}

#descriptionDIV p .stronger {
  display: block;
  font-size: 1.2rem;
}

.gmap_canvas iframe {
  border: 0;
}

#locationDIV,
#detailsDIV {
  background-color: white;
}

#locationDIV {
  padding: 60px 20px;
}

#detailsDIV {
  padding: 20px 0 60px;
}

#detailsDIV p {
  text-align: center;
}

#locationBox {
  width: 90%;
  border: 1px solid var(--color-primary);
  margin: auto;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  margin-bottom: 60px;
}

.overviewIMG {
  width: 100%;
  overflow: hidden;
}

#detailsDIV .overviewIMG img {
  max-width: 100%;
}

#detailsDIV #lote {
  width: 80%;
  max-width: 553px;
  margin: 40px 0 80px 0;
}

#detailsDIV ul {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

#detailsDIV li {
  margin-bottom: 10px;
  margin-left: 20px;
  text-align: left;
  font-size: 1.4rem;
}

.li {
  font-size: 1.25rem;
}

#detailsDIV .bigData {
  color: var(--color-primary);
  font-size: 1.4rem;
  text-decoration: underline;
}

#detailsDIV .smallData {
  font-size: 1.25rem;
}

#detailsDIV li.noItem {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  margin-bottom: 0;
  margin-left: 0;
  text-align: center;
}

#detailsDIV li.noItem img {
  width: 100%;
  max-width: 1053px;
  margin: 20px 0;
}

#detailsDIV p.noItem {
  display: none;
}

.button_search {
  top: 10px;
  right: 10px;
  position: absolute;
  width: 37px;
  height: 37px;
  cursor: pointer;
  filter: var(--filter-primary);
}

#videoDIV {
  padding: 0;
  margin: 0;
  position: relative;
}

#videoDIV video {
  width: 100%;
  height: auto;
  display: block;
}

#contactDIV {
  background-color: var(--color-primary);
  padding: 40px;
  color: #000
}

#contactDIV>div {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contactDIV input,
#contactDIV button {
  border: 1px solid black;
  background-color: var(--color-primary);
  display: block;
  margin-bottom: 20px;
  width: 100%;
  max-width: 320px;
  border-radius: 3px;
  height: 30px;
  padding: 0 10px;
  box-sizing: border-box;
}

#contactDIV input::placeholder {
  color: #000;
}

#contactDIV button {
  background-color: black;
  color: white;
  cursor: pointer;
}

@media (min-width: 425px) {
  #descriptionDIV p {
    text-align: center;
  }

  #descriptionDIV p .stronger {
    display: inline-block;
  }

  #detailsDIV p.lote {
    display: inline-block;
    width: 40%;
  }

  #detailsDIV ul {
    text-align: center;
    font-size: 1.5rem;
    display: inline-block;
    width: 55%;
    vertical-align: top;
    top: -30px;
  }

  #detailsDIV ul li {
    margin-bottom: 30px;
  }

  /* #detailsDIV li::before {
    content: "·";
    color: var(--color-primary);
    font-size: 3rem;
    font-weight: bold;
    left: -5px;
    top: 9px;
    position: relative;
} */

  #detailsDIV li.noItem {
    display: none;
  }

  #detailsDIV p.noItem {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    margin-bottom: 0;
    margin-left: 0;
    text-align: center;
  }

  #detailsDIV p.noItem img {
    width: 100%;
    max-width: 1053px;
    margin: 20px 0;
  }

  #detailsDIV li .bigData {
    font-size: 1.5rem
  }

  #detailsDIV li {
    margin-bottom: 10px;
    margin-left: 0;
    display: block;
    width: 100%;
  }
}